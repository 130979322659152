import { request } from '@/utils/request'

export function getFundProductList (params = {}) {
  return request.get('/fund', { params })
}

export function getFundProductDetail (id) {
  return request.get(`/fund/${id}`)
}

export function getFundGradeDetail (fundProductId) {
  return request.get(`/fund/${fundProductId}/grade`)
}

export function getSeriesData (id, params = {}) {
  return request.get(`/fund/${id}/series`, { params })
}
