<template>
  <div class="grade-detail">
    <b-container class="grade-detail-container">
      <b-row align-h="between">
        <b-col lg="8" md="12">
          <h2>{{ fundProductDetail.name }}</h2>
          <b-card class="total-card">
            <b-row cols="3" no-gutters>
              <b-col>
                <div class="total-item">
                  <h3>{{ fundGradeDetail.net_value | toFixed }}</h3>
                  <div>NV</div>
                </div>
              </b-col>
              <b-col>
                <div class="total-item">
                  <h3>{{ fundProductDetail.description }}</h3>
                  <div>策略类型</div>
                </div>
              </b-col>
              <b-col>
                <div class="total-item">
                  <h3 class="year-return-ratio">{{ fundGradeDetail.annualized_return_ratio | toPercent }}</h3>
                  <div>年化收益率</div>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <div class="chart-container mb-4">
            <div class="charts-header">
              <div class="charts-legend">
                <div class="charts-legend-item" :class="{hidden: legend.hidden}" v-for="legend in legends" :key="legend.name" @click="toggleLegend(legend)">
                  <div class="legend-symbol" :style="{backgroundColor: legend.color}"></div>
                  {{ legend.name }}
                </div>
              </div>
              <div class="charts-granularity">
                <div class="charts-granularity-item" :class="{active: currentGranularity===g.value}" @click="selectGranularity(g)" v-for="g in granularityList" :key="g.value">{{ g.label }}</div>
              </div>
            </div>
            <charts ref="charts" :option="chartOption" width="100%" height="500px"/>
          </div>
        </b-col>
        <b-col lg="4" md="12">
          <b-button type="light" variant="dark" pill block @click="showContactDialog">联系我们</b-button>
          <b-list-group class="detail-board mb-4">
            <b-list-group-item class="detail-board-item">
              <div class="label">更新时间</div>
              <div class="text">{{ fundProductDetail.update_time | toDateFmt }}</div>
            </b-list-group-item>
            <b-list-group-item class="detail-board-item">
              <div class="label">管理规模</div>
              <div class="text">{{ fundGradeDetail.aum | toFixed }} BTC</div>
            </b-list-group-item>
            <b-list-group-item class="detail-board-item">
              <div class="label">夏普比率</div>
              <div class="text"> {{ fundGradeDetail.sharp_ratio | toFixed }}</div>
            </b-list-group-item>
            <b-list-group-item class="detail-board-item">
              <div class="label">最大回撤</div>
              <div class="text">{{ fundGradeDetail.max_drawdown | toPercent }}</div>
            </b-list-group-item>
            <b-list-group-item class="detail-board-item">
              <div class="label">卡玛比率</div>
              <div class="text"> {{ fundGradeDetail.calmar_ratio | toFixed }}</div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
    <Dialog v-if="contactDialogVisible" title="联系我们" @close="contactDialogVisible = false">
      <img style="width: 100%;" src="@/assets/contact-qrcode.png" alt=""/>
      <p style="margin: 1em auto;font-size: 16px;">请添加微信客服，备注【宽途官网】</p>
    </Dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import Charts from '@/components/Charts'
import { getFundGradeDetail, getFundProductDetail, getSeriesData } from '@/api/fund'
import dayjs from 'dayjs'
import { toPercent, toRoundFixed } from '@/utils/math'
import Dialog from '@/components/Dialog'

const colors = ['#4421a5']

const gList = ['7d', '30d', '90d', 'all']

const gMap = {}

const decodeTimeAbbr = (abbr = '') => {
  return abbr.match(/[a-zA-Z]+|[0-9]+/g)
}

gList.forEach(g => {
  if (g === 'all') {
    gMap[g] = []
  } else {
    const [n, u] = decodeTimeAbbr(g)
    gMap[g] = [dayjs().add(-1 * (~~n - 1), u).startOf('day').unix(), dayjs().endOf('day').unix()]
  }
})

export default {
  name: 'FundProductDetail',
  components: { Dialog, Charts },
  data () {
    return {
      contactDialogVisible: false,
      fundProductDetail: {},
      fundGradeDetail: {},
      legends: [
        {
          name: '净值',
          hidden: false,
          color: colors[0],
        },
      ],
      granularityList: gList.map(g => {
        return {
          label: g.toUpperCase(),
          value: g,
        }
      }),
      currentGranularity: 'all',
      startTime: '',
      endTime: '',
      chartOption: {
        xAxis: {
          type: 'category',
          boundaryGap: false,
        },
        yAxis: {
          type: 'value',
          min: 1,
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            show: false,
          },
        },
        legend: {
          show: false,
          data: ['净值'],
        },
        tooltip: {
          trigger: 'axis',
        },
        series: [
          {
            name: '净值',
            data: [],
            type: 'line',
            symbol: 'circle',
            showSymbol: false,
            symbolSize: 10,
            lineStyle: {
              color: colors[0],
              width: 1,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: colors[0],
              }, {
                offset: 1,
                color: 'rgba(58,77,233,0.1)',
              }]),
            },
          },
        ],
      }
      ,
    }
  },
  computed: {
    fdId () {
      return this.$route.query.id
    },
  },
  filters: {
    toFixed (val) {
      return toRoundFixed(val, 4)
    },
    toPercent,
    toDateFmt (val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    },
  },
  created () {
    this.fetchSeriesData()
    if (this.fdId) {
      this.fetchGradeDetail()
    }
  },
  methods: {
    async fetchGradeDetail () {
      await getFundProductDetail(this.fdId).then(res => {
        this.fundProductDetail = res.data
      })
      await getFundGradeDetail(this.fdId).then(res => {
        this.fundGradeDetail = res.data
      })
    },
    fetchSeriesData () {
      const start_time = gMap[this.currentGranularity]?.[0]
      const end_time = gMap[this.currentGranularity]?.[1]
      return getSeriesData(this.fdId, {
        start_time,
        end_time,
      }).then(res => {
        const data = (res.data?.series || []).map(s => ({
          name: s.timestamp,
          value: toRoundFixed(s.net_value, 4),
        }))
        this.$refs.charts?.chart?.setOption({
          xAxis: {
            data: data.map(d => d.name),
          },
          series: [{ data }],
        })
      })
    },
    toggleLegend (legend) {
      const { name } = legend
      if (this.$refs.charts?.chart) {
        this.$refs.charts.chart.dispatchAction({
          type: 'legendToggleSelect',
          // 图例名称
          name,
        })
        legend.hidden = !legend.hidden
      }
    },
    selectGranularity (g) {
      this.currentGranularity = g.value
      this.fetchSeriesData()
    },
    showContactDialog () {
      this.contactDialogVisible = true
    },
  },
}
</script>

<style lang="less" scoped>
@import '../assets/styles/variables';

.grade-detail {
  padding-top: 76px;
  font-size: 12px;
  background: #f9f9fc;
  min-height: 100vh;

  h2 {
    font-weight: 700;
  }
}

.grade-detail-container {
  margin-top: 50px;
}

.detail-board {
  margin-top: 30px;
  padding: 0.5rem;
  background: rgba(34, 37, 49, 0.05);
}

.detail-board-item {
  display: flex;
  justify-content: space-between;
  border: 0;
  background: transparent;

  &:not(:last-child) {
    border-bottom: 1px dashed #999;
  }

  .text {
    font-weight: 700;
  }

}

.total-item {

  h3 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
  }
}

.year-return-ratio {
  color: @green;
}

.total-card {
  margin-top: 30px;

}

.chart-container {
  margin-top: 40px;
}

.charts-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgba(34, 37, 49, 0.5);
  padding: 10px 0;
}

.charts-granularity {
  display: flex;
}

.charts-legend {

}

.charts-granularity-item {
  font-size: 12px;
  color: rgba(34, 37, 49, 0.5);
  font-weight: bold;
  user-select: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover, &.active {
    color: #222531;
  }
}

.charts-legend-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  .legend-symbol {
    margin-right: 0.5em;
    display: inline-block;
    width: 1em;
    height: 2px;
  }

  &.hidden {
    .legend-symbol {
      background: #999 !important;
    }
  }
}
</style>
